$white: #ffffff;
$purple: #8745a8;
.form {
  display: flex;
  flex-direction: column;
  &__box {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 0.25rem;
    width: 20rem;
    margin-inline: auto;
    background-color: $purple;
    border-radius: 0rem;
    & h2 {
      font-size: 2.5rem;
      font-weight: 700;
      line-height: 3.5625rem;
    }
  }
  &__label {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    label {
      margin: 1.25rem 0.3125rem 0.3125rem;
      font-family: "Poppins";
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 1.3125rem;
      color: $white;
    }
    input {
      background-color: #ffffff;
      width: 15.375rem;
      border: 0.125rem solid var(--White, #fff);
      border-radius: 0.3125rem;
      padding-left: 1rem;
      padding-block: 0.8125rem;
      font-size: 0.875rem;
      line-height: 1.3125rem;
      font-weight: 300;

      &:focus-visible {
        outline: #363636;
        border-color: #363636;
      }
    }
  }
  textarea {
    min-height: 5.625rem;
    background-color: #ffffff;
    border: 0.125rem solid var(--White, #fff);
    border-radius: 0.3125rem;
    padding-left: 1.25rem;
    padding-top: 0.75rem;
    width: 15.125rem;
    &:focus-visible {
      outline: #363636;
      border-color: #363636;
    }
  }
  &__button {
    background-color: $white;
    border: none;
    border-radius: 0.5rem;
    color: $purple;
    width: 16.75rem;
    padding: 0.625rem 1.5rem;
    height: 2.875rem;
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
    font-family: "Poppins";
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.625rem;
    cursor: pointer;
  }
  @media screen and (min-width: 1511px) {
    position: absolute;
    right: 2.375rem;
    &__box {
      background-color: $purple;
      width: 31.25rem;
      height: 34.375rem;
      position: relative;
      left: -2.0625rem;
      z-index: 10;
      border-radius: 0.5rem;
    }
    &__label {
      input {
        width: 25.375rem;
      }
    }
    textarea {
      width: 25.125rem;
      font-size: 0.875rem;
    }
    &__button {
      width: 26.75rem;
      height: 3rem;
      margin-bottom: 0rem;
      font-size: 1.125rem;
      font-style: normal;
      font-weight: 300;
    }
  }
}

::placeholder {
  font-family: "Poppins";
}
