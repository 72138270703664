$white: #ffffff;
$purple: #8745a8;
.hero {
  &__bigBox {
    display: flex;
    align-items: flex-start;
    scroll-margin-top: 8.75rem;
    margin-top: 4rem;
  }
  &__box {
    position: relative;
    right: 1.5rem;
  }
  &__leftFlower {
    width: 12.125rem;
    margin-top: 0.5887rem;
  }
  &__centerFlower {
    width: 15rem;
    margin-left: 10.75rem;
  }
  &__rightFlower {
    margin-left: 11rem;
    &--mobile {
      display: none;
    }
  }
  &__title {
    color: $white;
    width: 28.125rem;
    font-size: 3.375rem;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 1rem;
    margin-top: 10.1875rem;
  }
  &__paragraph {
    color: $white;
    width: 27.5625rem;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 300;
    margin-bottom: 2.25rem;
  }
  &__button {
    &--contact {
      border: none;
      font-size: 1.125rem;
      font-style: normal;
      font-weight: 300;
      color: $white;
      background-color: $purple;
      padding: 0.75rem 1.625rem;
      border-radius: 0.5rem;
      margin-right: 1.0625rem;
      font-family: Poppins;
      cursor: pointer;
    }
    &--learnMore {
      color: $white;
      background-color: #121212;
      border: 0.0625rem solid $white;
      padding: 0.75rem 1.625rem;
      border-radius: 0.5rem;
      font-size: 1.125rem;
      font-style: normal;
      font-weight: 300;
      font-family: Poppins;
      cursor: pointer;
    }
  }
  @media only screen and (max-width: 1511px) {
    &__rightFlower {
      display: none;
      &--mobile {
        position: absolute;
        right: 0rem;
        display: initial;
        bottom: -8.6875rem;
      }
    }
    &__leftFlower {
      display: none;
    }
    &__centerFlower {
      width: 4.625rem;
      position: absolute;
      margin-left: 0rem;
      right: 0rem;
      top: 0rem;
      transform: scaleY(-1) rotate(1deg);
    }
    &__box {
      position: relative;
      width: 20rem;
      right: 0rem;
    }
    &__button--learnMore {
      display: none;
    }
    &__button--contact {
      width: 8.75rem;
      font-size: 1rem;
      font-style: normal;
      font-weight: 300;
      position: relative;
      z-index: 2;
      margin-left: 1.875rem;
    }
    &__title {
      width: 15.25rem;
      font-family: Poppins;
      font-size: 1.8125rem;
      font-style: normal;
      font-weight: 600;
      margin-top: 0rem;
      margin-left: 1.875rem;
    }
    &__bigBox {
      align-items: initial;
      justify-content: center;
      position: relative;
    }
    &__paragraph {
      width: 15rem;
      font-size: 1rem;
      font-style: normal;
      font-weight: 300;
      position: relative;
      z-index: 2;
      margin-left: 1.875rem;
    }
  }
}
