$white: #ffffff;
$purple: #8745a8;

.carousel {
  color: $white;
  background-color: $purple;
  width: 100%; // Flexible width
  max-width: 21.875rem; // Constrain max width
  height: 31.875rem;
  margin-left: 0; // No offset
  display: flex;
  flex-direction: column;
  align-items: center;

  &__box {
    width: 100%; // Full width for container
    max-width: 69.375rem; // Constrain max width for large screens
    margin: 0 auto; // Center it within its container
    position: relative;
    z-index: 5;
    right: 10.625rem;
  }

  &__member {
    width: 100%; // Flexibility in image size
    max-width: 19.375rem; // Constrain max size
    margin-top: 1.25rem;
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }

  &__title {
    font-family: Poppins;
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 600;
    line-height: 2.1875rem;
    margin-top: 0.625rem;
    margin-bottom: 0.3125rem;
  }

  &__subTitle {
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 300;
    line-height: 1.6rem;
    margin-top: 0;
    margin-bottom: 0.3125rem;
  }

  @media only screen and (max-width: 1511px) {
    width: 100%; // Flexibility for smaller screens
    max-width: 16.125rem;
    margin-left: 0;

    &__box {
      width: 100%;
      max-width: 16.125rem;
      height: 27.125rem;
      text-align: center;
      margin: 0 auto; // Ensure centering
      right: 0rem;
    }

    &__member {
      width: 100%;
      max-width: 16.125rem;
      margin-top: 0;
    }

    &__title {
      font-size: 1.125rem;
      font-weight: 600;
    }

    &__subTitle {
      font-size: 1rem;
      font-weight: 300;
    }
  }
}
