$white: #ffffff;
$purple: #8745a8;

.footer {
  display: flex;
  position: relative;
  justify-content: center;
  &__leftFlower {
    position: absolute;
    top: -8.5rem;
    left: 0;
    z-index: 3;
  }
  &__rightFlower {
    position: absolute;
    right: 0rem;
    top: -1.5625rem;
  }
  &__social {
    display: flex;
    justify-content: center;
    margin-top: 1.875rem;
    &--facebook {
      margin-right: 1.3944rem;
    }
  }
  a {
    color: $white;
  }
  &__creds {
    color: $white;
    margin-bottom: 0rem;
    text-align: center;
  }

  @media only screen and (max-width: 1511px) {
    width: 20rem;

    &__textBox {
      display: flex;
      flex-direction: column;
      margin-top: 2.9375rem;
    }
    &__leftFlower {
      width: 20rem;
      height: 15.0322rem;
      top: 11.125rem;
      padding-bottom: 1.5625rem;
    }
    &__rightFlower {
      display: none;
    }
    &__box {
      display: flex;
      justify-content: center;
    }
    &__social {
      margin-top: 1.875rem;
    }
  }
}
