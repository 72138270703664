$white: #ffffff;
$purple: #8745a8;
.meet {
  color: $white;
  display: flex;
  width: 94.5rem;
  justify-content: space-between;
  scroll-margin-top: 6.25rem;
  margin-bottom: 5.3125rem;
  &__title {
    font-size: 1.75rem;
    font-style: normal;
    font-weight: 600;
    margin-top: 3rem;
    margin-bottom: 1rem;
  }
  &__title--box {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    position: relative;
    z-index: 5;
    right: 11.4375rem;
  }
  &__flowers {
    &--topRight {
      position: relative;
      right: 19.5rem;
      top: 0.9375rem;
      z-index: 0;
    }
    &--midRight {
      position: relative;
      right: 4.8125rem;
      top: 17.875rem;
    }
    &--topLeft {
      position: relative;
      z-index: 0;
      justify-self: flex-start;
    }
  }
  &__para {
    width: 39.625rem;
  }

  @media only screen and (max-width: 1511px) {
    justify-content: center;
    width: 20rem;
    &__bigBox {
      display: flex;
      justify-content: center;
    }
    &__flowers {
      &--topLeft {
        width: 6.7966rem;
        height: 4.375rem;
        transform: rotate(-30deg);
        left: 2.125rem;
        top: 2.5rem;
      }
      &--topRight {
        width: 3.1604rem;
        height: 3.3553rem;
        right: 1.5625rem;
        top: 20.0625rem;
      }
      &--midRight {
        width: 3.1604rem;
        height: 3.3553rem;
        right: 1.125rem;
        top: 30rem;
      }
    }
    &__para {
      width: 16.25rem;
    }
    &__title {
      position: relative;
      z-index: 2;
    }
    &__title--box {
      position: inherit;
    }
  }
}
