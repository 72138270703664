@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

$white: #ffffff;
$purple: #8745a8;

li {
  list-style: none;
}
.header-logo {
  margin-left: 9.8125rem;
  margin-right: 9rem;
  position: relative;
  display: flex;
}

.coqui-header-logo {
  width: 14.4916rem;
  cursor: pointer;
}

.navigation {
  display: flex;
  background-color: #121212;
  color: $white;
  width: 100vw;
  height: 5.5rem;
  position: sticky;
  z-index: 9999;

  &__logo--mobile {
    display: none;
  }
  &__social {
    display: flex;
    margin-right: 12.625rem;
    &--facebook {
      margin-right: 1.3944rem;
    }
  }
}

a {
  text-decoration: none;
}
.navigation__bigbox {
  width: 94.5rem;
  display: flex;
  align-items: center;
  background-color: #121212;
  color: $white;
  height: 8.9375rem;
  position: fixed;
  z-index: 9999;
  padding-bottom: 0.5rem;
}

.navigation ul {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  width: 100vw;
  padding: 0rem;
  overflow: hidden;
  z-index: 10;
  left: 0rem;
}
.nav-link {
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-right: 3.0625rem;
  color: $white;
  cursor: pointer;
  &--home {
    display: none;
  }
}
.navigation ul Link {
  list-style-type: none;
  padding-right: 0.625rem;
}
.hamburger {
  display: none;
  z-index: 6;
}
.header-logo-box {
  display: none;
}
@media (max-width: 1511px) {
  .hamburger {
    display: initial;
    padding-top: 0.625rem;
    z-index: 6;
  }
  .navigation__social {
    display: none;
  }
  .header-logo {
    margin-top: 1.25rem;
    margin-right: 6.5625rem;
  }

  .navigation ul {
    background-color: #121212;
    height: 100vh;
    flex-direction: column;
    align-items: center;
    flex-wrap: nowrap;
    width: 100vw;
    margin-top: 5.8125rem;
    margin-right: 12.5rem;
    position: fixed;
    color: $white;
  }
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1.5rem;
}

.hamburger {
  display: none;
}

.bar {
  display: block;
  width: 1.5625rem;
  height: 0.1875rem;
  margin: 0.3125rem auto;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background-color: #101010;
}

.nav-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 0;
}

.nav-item {
  margin-left: 1rem;
}

.nav-logo {
  font-size: 2.1rem;
  font-weight: 500;
  color: #482ff7;
}

@media only screen and (max-width: 1511px) {
  .nav-menu {
    position: fixed;
    top: 5rem;
    flex-direction: column;
    background-color: #121212;
    width: 100%;
    border-radius: 0.625rem;
    text-align: center;
    transition: 0.3s;
    box-shadow: 0 0.625rem 1.6875rem rgba(0, 0, 0, 0.05);
  }
  .navigation__bigbox {
    width: 20rem;
    display: initial;
    height: 5.9375rem;
    padding-bottom: 0rem;
  }
  .header-logo-box {
    display: flex;
  }
  .header-logo-1 {
    margin-top: 0.8125rem;
    margin-left: 1.375rem;
    margin-right: 11rem;
  }
  .header-logo {
    display: none;
  }
  .nav-menu.active {
    left: 0;
  }
  .navigation {
    display: flex;
    justify-content: center;
    width: inherit;
  }

  .nav-link {
    font-family: Poppins;
    font-size: 1.875rem;
    font-weight: 300;
    line-height: 2.8125rem;
    letter-spacing: 0em;
    text-align: left;
    color: $white;
    cursor: pointer;
    margin-right: 1rem;
    margin-top: 1.875rem;
    &--home {
      display: flex;
    }
  }

  .nav-item {
    margin: 2.5rem 0;
  }

  .hamburger {
    display: block;
    cursor: pointer;
  }

  .hamburger.active .bar:nth-child(2) {
    opacity: 0;
  }

  .hamburger.active .bar:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
  }

  .hamburger.active .bar:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
  }
}
