$white: #ffffff;
$purple: #8745a8;

.App {
  background-color: #121212;
  display: flex;
  justify-content: center;
}
body {
  background-color: #121212;
  font-family: Poppins;
}
.app {
  &__box {
    width: 1512px;
  }
}
