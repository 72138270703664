@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
$white: #ffffff;
$purple: #8745a8;

.contact {
  display: flex;
  justify-content: center;
  position: relative;
  left: 1.0625rem;
  scroll-margin-top: 6.25rem;
  width: 20rem;
  &__biggestBox {
    display: flex;
    justify-content: center;
  }
  &__leftFlower {
    transform: scaleY(-1) rotate(-20deg);
    position: relative;
    z-index: 0;
    left: 3.5625rem;
    top: 0rem;
    width: 3.5868rem;
    height: 4.923rem;
  }
  &__rightFlower {
    transform: scaleY(-1) rotate(180deg);
    position: relative;
    z-index: 2;
    right: 5.6875rem;
    top: 37.875rem;
    width: 11.1811rem;
    height: 8.0261rem;
  }
  &__bigBox {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 2.875rem;
  }
  &__background {
    background-color: $white;
    background-repeat: no-repeat;
    background-size: cover;
    height: auto;
    z-index: 1;
    width: 20rem;
  }
  &__h1 {
    font-family: "Archivo";
    font-style: normal;
    font-weight: 850;
    font-size: 2.75rem;
    line-height: 4.375rem;
    color: $purple;
    margin-top: 3.875rem;
    margin-bottom: 0.9375rem;
    letter-spacing: 0.07rem;
    width: 16.25rem;
  }
  &__para {
    color: $purple;
    font-size: 1rem;
    width: 15.75rem;
  }
  &__info {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 1.125rem;
    color: #ffffff;
  }
  &__box {
    width: 16.25rem;
    text-align: start;
    margin-left: 1.875rem;
  }
  a {
    text-decoration: none;
  }
  &__emailIcon {
    margin-right: 0.75rem;
  }
  &__textIcon {
    margin-right: 0.75rem;
  }
  &__container {
    display: flex;
    align-items: center;
    margin-bottom: 1.5625rem;
    margin-top: 2.4375rem;
  }

  @media screen and (min-width: 1511px) {
    &__background {
      width: 55.375rem;
      height: 36.875rem;
      border-radius: 0.5rem;
      position: relative;
      right: 20.3125rem;
    }
    &__leftFlower {
      transform: scaleY(-1) rotate(-20deg);
      z-index: 0;
      left: -7.3125rem;
      top: -5.5625rem;
      width: 19.6652rem;
      height: 23.6457rem;
    }
    &__rightFlower {
      position: absolute;
      z-index: 4;
      left: 33rem;
      top: 5.125rem;
      width: 30.2969rem;
      height: 21.748rem;
      transform: rotate(0deg);
    }

    &__para {
      width: 26.5625rem;
      font-size: 1rem;
      font-style: normal;
      font-weight: 300;
    }
    &__bigBox {
      width: 70.3125rem;

      margin-bottom: 4.5625rem;
      display: flex;
      flex-direction: row;
      position: relative;
    }
    &__h1 {
      width: 25.6875rem;
      margin-bottom: 2.0625rem;
      font-size: 3.375rem;
      font-style: normal;
      font-weight: 600;
    }
    &__box {
      margin-left: 4.0625rem;
    }
  }
}
